@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

html,
body,
#root,
.app{
    height: 100%;
    width: 100%;
    font-family: "Inter", sans-serif;
}

/* Customize scrollbar width */
::-webkit-scrollbar {
    width: 12px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
    background: #f5faff;
    border-radius: 10px;
    margin: 2px;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: #0d47a1;
    border-radius: 10px;
    border: 2px solid #d0e6f8;
}

/* Scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #0b3a7a;
}

/* Scrollbar track on hover */
::-webkit-scrollbar-track:hover {
    background: #e0f2fe;
}
